import React from 'react';
import { NavLink } from 'react-router-dom';
import { withFirebase } from '../firebase';
import '../tailwind.output.css'
 
const SignOutButton = ({ firebase }) => {
  
return(
  <button  className='bg-black inline-flex items-center justify-center border ml-2 p-2 rounded-md text-white hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white' as={NavLink} to="/" onClick={()=>{firebase.doSignOut();}}>Sign out</button>
)};
 
export default withFirebase(SignOutButton);