import React, { Component } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import { SpinnerCircular } from "spinners-react";
import { Line } from "react-chartjs-2";
import 'chart.js/auto';
import fetchClient from '../utils/fetchClient';
  class Stacked extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
         bq:[],
         bq_req:[],
         jira:[],
         gl:[]
    
        
        };
        
      }
      componentDidMount() {
       console.log('hello stack')
       fetchClient('/getgraphdata', {
        
       method: 'POST',
       headers: { "Content-Type": "application/json" },
       body: JSON.stringify({"sql": "select * from `jlr-eng-ddpdashboard-prod.nightletter.BQ_summary` order by PARSE_DATE('%B %d, %Y',Timestamp) desc LIMIT 5"})
     }).then((res) =>   res.json().then((data) => {
       // Setting a data from api
       console.log(data)
       data.forEach(item => {
        item.dateObject = new Date(item.Timestamp);
     });
     // Sort the array based on the Date objects
     data.sort((a, b) => a.dateObject - b.dateObject);
     // Remove the dateObject if it's not needed anymore
     data.forEach(item => {
        delete item.dateObject;
     });
     for(var i=0;i<data.length;i++){
        this.state.bq.push({name:data[i]['Timestamp'],Others:Number(JSON.parse(data[i]['test'].replaceAll("'",'"').replace('None','"None"'))['Total'])-Number(JSON.parse(data[i]['test'].replaceAll("'",'"').replace('None','"None"').replace("Pass","PASS"))['PASS'])-(Number(JSON.parse(data[i]['test'].replaceAll("'",'"').replace('None','"None"').replace("Fail","FAIL"))['FAIL'])+Number(JSON.parse(data[i]['test'].replaceAll("'",'"').replace('None','"None"'))['Pending-Validation'])+Number(JSON.parse(data[i]['test'].replaceAll("'",'"').replace('None','"None"'))['Executing'])),Pass:Number(JSON.parse(data[i]['test'].replaceAll("'",'"').replace('None','"None"').replace("Pass","PASS"))['PASS']),Fail:Number(JSON.parse(data[i]['test'].replaceAll("'",'"').replace('None','"None"').replace("Fail","FAIL"))['FAIL'])+Number(JSON.parse(data[i]['test'].replaceAll("'",'"').replace('None','"None"'))['Pending-Validation'])+Number(JSON.parse(data[i]['test'].replaceAll("'",'"').replace('None','"None"'))['Executing'])})
        this.state.bq_req.push({name:data[i]['Timestamp'],'Not-Executed':Number(JSON.parse(data[i]['data'].replaceAll("'",'"'))['Total Cases'])-Number(JSON.parse(data[i]['data'].replaceAll("'",'"'))['Total Executed(NOK + OK)']),'Executed':Number(JSON.parse(data[i]['data'].replaceAll("'",'"'))['Total Executed(NOK + OK)'])})
    }
     this.setState({bq:this.state.bq,bq_req:this.state.bq_req})
    console.log(this.state.bq)
     }))

     fetchClient('/getgraphdata', {
        
     method: 'POST',
     headers: { "Content-Type": "application/json" },
     body: JSON.stringify({"sql": "select * from `jlr-eng-ddpdashboard-prod.nightletter.JIRA_summary` order by PARSE_DATE('%B %d, %Y',Timestamp) desc LIMIT 5"})
   }).then((res) =>   res.json().then((data) => {
     // Setting a data from api
     console.log(data)
     data.forEach(item => {
      item.dateObject = new Date(item.Timestamp);
   });
   // Sort the array based on the Date objects
   data.sort((a, b) => a.dateObject - b.dateObject);
   // Remove the dateObject if it's not needed anymore
   data.forEach(item => {
      delete item.dateObject;
   });
     let ivs_data=[]
    
     for(var i=0;i<data.length;i++){
        ivs_data.push({name:data[i]['Timestamp'],'Without ICA':Number(JSON.parse(data[i].ivs_data.replaceAll("'",'"'))[0]['red']),'Fix in place':Number(JSON.parse(data[i].ivs_data.replaceAll("'",'"'))[0]['amber']),'Issues Resolved':Number(JSON.parse(data[i].ivs_data.replaceAll("'",'"'))[0]['green'])})
      
  }
  let ivs_data_drop10=[]
    
  for(var i=0;i<data.length;i++){
    if(data[i].ivs_data_drop10!=null){
      ivs_data_drop10.push({name:data[i]['Timestamp'],'Without ICA':Number(JSON.parse(data[i].ivs_data_drop10.replaceAll("'",'"'))[0]['red']),'Fix in place':Number(JSON.parse(data[i].ivs_data_drop10.replaceAll("'",'"'))[0]['amber']),'Issues Resolved':Number(JSON.parse(data[i].ivs_data_drop10.replaceAll("'",'"'))[0]['green'])})
    }
     
   
}
let ivs_data_vb=[]
    
for(var i=0;i<data.length;i++){
  if(data[i].ivs_data_vb!=null){
    ivs_data_vb.push({name:data[i]['Timestamp'],'Without ICA':Number(JSON.parse(data[i].ivs_data_vb.replaceAll("'",'"'))[0]['red']),'Fix in place':Number(JSON.parse(data[i].ivs_data_vb.replaceAll("'",'"'))[0]['amber']),'Issues Resolved':Number(JSON.parse(data[i].ivs_data_vb.replaceAll("'",'"'))[0]['green'])})
  }
   
 
}
  let sw8_data=[]
    
  for(var i=0;i<data.length;i++){
    sw8_data.push({name:data[i]['Timestamp'],Minimun:Number(JSON.parse(data[i].defect_resolution.replaceAll("'",'"'))[0]['sw8_min']),Maximum:Number(JSON.parse(data[i].defect_resolution.replaceAll("'",'"'))[0]['sw8_max']),Average:Number(JSON.parse(data[i].defect_resolution.replaceAll("'",'"'))[0]['sw8_avg'])})
   
}
let all_data=[]
    
for(var i=0;i<data.length;i++){
    all_data.push({name:data[i]['Timestamp'],Minimun:Number(JSON.parse(data[i].defect_resolution.replaceAll("'",'"'))[1]['all_min']),Maximum:Number(JSON.parse(data[i].defect_resolution.replaceAll("'",'"'))[1]['all_max']),Average:Number(JSON.parse(data[i].defect_resolution.replaceAll("'",'"'))[1]['all_avg'])})
 
}
 console.log(all_data,'all')
  this.state.jira['ivs']=ivs_data
  this.state.jira['ivs_drop10']=ivs_data_drop10
  this.state.jira['ivs_vb']=ivs_data_vb
  this.state.jira['sw8']=sw8_data
  this.state.jira['all']=all_data
   this.setState({jira:this.state.jira})
 
   }))
   fetchClient('/getgraphdata', {
        
   method: 'POST',
   headers: { "Content-Type": "application/json" },
   body: JSON.stringify({"sql": "select * from `jlr-eng-ddpdashboard-prod.nightletter.gitlab_summary` order by PARSE_DATE('%B %d, %Y',Timestamp) desc LIMIT 5"})
 }).then((res) =>   res.json().then((data) => {
   // Setting a data from api
   console.log(data)
   let unique_sub=[]
   data.forEach(item => {
    item.dateObject = new Date(item.Timestamp);
 });
 // Sort the array based on the Date objects
 data.sort((a, b) => a.dateObject - b.dateObject);
 // Remove the dateObject if it's not needed anymore
 data.forEach(item => {
    delete item.dateObject;
 });
  //  data=data.sort((a,b)=> a['Timestamp'] > b['Timestamp']? 1 : -1)
   console.log(data)
   for(var i=0;i<data.length;i++){
    unique_sub.push({name:data[i]['Timestamp'],TotalMR:JSON.parse(data[i].gitlab_summary.replaceAll("'",'"'))['Total_MR'],UniqueSubsystem:JSON.parse(data[i].gitlab_summary.replaceAll("'",'"'))['unique-sub']})
    
}
let master=[]
  
for(var i=0;i<data.length;i++){
  master.push({name:data[i]['Timestamp'],ThisWeek:JSON.parse(data[i].gitlab_summary.replaceAll("'",'"'))['master_this_week'],LastWeek:JSON.parse(data[i].gitlab_summary.replaceAll("'",'"'))['master_last_week']})
 
}
let drop8=[]
  
for(var i=0;i<data.length;i++){
  drop8.push({name:data[i]['Timestamp'],ThisWeek:JSON.parse(data[i].gitlab_summary.replaceAll("'",'"'))['drop8_this_week'],LastWeek:JSON.parse(data[i].gitlab_summary.replaceAll("'",'"'))['drop8_last_week']})
 
}
let drop10=[]
  
for(var i=0;i<data.length;i++){
  drop10.push({name:data[i]['Timestamp'],ThisWeek:JSON.parse(data[i].gitlab_summary.replaceAll("'",'"'))['drop10_this_week'],LastWeek:JSON.parse(data[i].gitlab_summary.replaceAll("'",'"'))['drop10_last_week']})
 
}

this.state.gl['unique_sub']=unique_sub
this.state.gl['master']=master
this.state.gl['drop8']=drop8
this.state.gl['drop10']=drop10
 this.setState({gl:this.state.gl})
 console.log('gitlab',this.state.gl)

 }))
      }
    render() {
      const data = {
        labels: ["06 Aug","05 Aug","02 Aug"],
        datasets: [
          {
            color: "#fff",
            label: "Drop8-pre-release",
            fill: false,
            lineTension: 0.3,
            backgroundColor: "#2a2c30",
            borderColor: "#2a2c30",
            borderCapStyle: "round",
            borderJoinStyle: "round",
            pointBorderColor: "#2a2c30",
            pointBackgroundColor: "#fff",
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "#2a2c30",
            pointHoverBorderColor: "#fff",
            pointHoverBorderWidth: 5,
            pointRadius: 0,
            pointHitRadius: 5,
            data: [-1,-1,-1]
          },
          {
            color: "#000",
            label: "Drop8-release",
            fill: false,
            lineTension: 0.3,
            backgroundColor: "#f84c1e",
            borderColor: "#f84c1e",
            borderCapStyle: "round",
            borderJoinStyle: "round",
            pointBorderColor: "#f84c1e",
            pointBackgroundColor: "#fff",
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "#f84c1e",
            pointHoverBorderColor: "#fff",
            pointHoverBorderWidth: 5,
            pointRadius: 0,
            pointHitRadius: 5,
            data: [1,1,1]
          },
          {
            color: "#ffbf00",
            label: "Drop10-staging",
            fill: false,
            lineTension: 0.3,
            backgroundColor: "#ffbf00",
            borderColor: "#ffbf00",
            borderCapStyle: "round",
            borderJoinStyle: "round",
            pointBorderColor: "#f84c1e",
            pointBackgroundColor: "#fff",
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "#f84c1e",
            pointHoverBorderColor: "#fff",
            pointHoverBorderWidth: 5,
            pointRadius: 0,
            pointHitRadius: 5,
            data: [-1,-1,-1]
          }
        ]
      };
    
      const options = {
        scales: {
          yAxes: [
            {
              ticks: {
                stepSize: 0.5,
                font: {
                  size: 14,
                }
              },
              gridLines: {
                drawTicks: false
              }
            }
          ],
          xAxes: [
            {
              gridLines: {
                display: false
              },ticks: {
               
                font: {
                  size: 14,
                }
              },
            }
          ]
        }
       
      };
    
        return (
            
                <>
                {/* <div style={{'fontSize':'xxx-large'}}className='mb-5 text-center text-5xl font-bold'>Historical Data</div> */}
                {this.state.bq.length!==0?
                <>
               <div className='ml-20 flex row'>
               <div className='col'>
                <h1 className='text-left font-bold text-2xl ml-20 pl-20'>Total SW8 IVS Blocker Issues :</h1>
      <BarChart
        width={500}
        height={300}
        data={this.state.jira['ivs']}
       
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="Fix in place" stackId="a" fill="#ffbf00" />
        <Bar dataKey="Issues Resolved" stackId="a" fill="#82ca9d" />
        <Bar dataKey="Without ICA" stackId="a" fill="#FF0000" />
       
      </BarChart>
      </div>
      <div className='col'>
                <h1 className='text-left font-bold text-2xl ml-20 pl-20'>Total SW10 IVS Blocker Issues :</h1>
      <BarChart
        width={500}
        height={300}
        data={this.state.jira['ivs_drop10']}
       
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="Fix in place" stackId="a" fill="#ffbf00" />
        <Bar dataKey="Issues Resolved" stackId="a" fill="#82ca9d" />
        <Bar dataKey="Without ICA" stackId="a" fill="#FF0000" />
       
      </BarChart>
      </div>
      <div className='col'>
                <h1 className='text-left font-bold text-2xl ml-20 pl-20'>Total VB Blocker Issues :</h1>
      <BarChart
        width={500}
        height={300}
        data={this.state.jira['ivs_vb']}
       
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="Fix in place" stackId="a" fill="#ffbf00" />
        <Bar dataKey="Issues Resolved" stackId="a" fill="#82ca9d" />
        <Bar dataKey="Without ICA" stackId="a" fill="#FF0000" />
       
      </BarChart>
      </div>
            
      </div>
      {/* <div className='ml-20 mt-5 flex row'>   <div className='col'>
                <h1 className='text-left font-bold text-2xl ml-20 pl-20'>Total Software Requirements :</h1>
      <BarChart
        width={500}
        height={300}
        data={this.state.bq_req}
       
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="Not-Executed" stackId="a" fill="#8884d8" />
        <Bar dataKey="Executed" stackId="a" fill="#82ca9d" />
       
      </BarChart>
      </div>
      <div className='col'>
      <h1 className='text-left font-bold text-2xl ml-20 pl-20'>Total Software Tests :</h1>
      <BarChart
        width={500}
        height={300}
        data={this.state.bq}
       
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="Others" stackId="a" fill="#8884d8" />
        <Bar dataKey="Pass" stackId="a" fill="#82ca9d" />
        <Bar dataKey="Fail" stackId="a" fill="#FF0000" />
      </BarChart>
      </div></div> */}
      <div className='ml-20 mt-5 flex row'>
        <div className='col'>
        <h1 className='text-left font-bold text-2xl ml-20 pl-20'>Metrics for SW8 Defect Resolution :</h1>
      <BarChart
        width={500}
        height={300}
        data={this.state.jira['sw8']}
       
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="Average" stackId="a" fill="#ffbf00" />
        <Bar dataKey="Minimum" stackId="a" fill="#82ca9d" />
        <Bar dataKey="Maximum" stackId="a" fill="#FF0000" />
       
      </BarChart>
        </div>
        <div className='col'>
        <h1 className='text-left font-bold text-2xl ml-20 pl-20'>Metrics for All Defect Resolution :</h1>
      <BarChart
        width={500}
        height={300}
        data={this.state.jira['all']}
       
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="Average" stackId="a" fill="#ffbf00" />
        <Bar dataKey="Minimum" stackId="a" fill="#82ca9d" />
        <Bar dataKey="Maximum" stackId="a" fill="#FF0000" />
       
      </BarChart>
        </div>
        <div className='col'>
        <h1 className='text-left font-bold text-2xl ml-20 pl-20'>No of Sub-systems into all branches/Total MR :</h1>
      <BarChart
        width={500}
        height={300}
        data={this.state.gl['unique_sub']}
       
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="TotalMR" stackId="a" fill="#ffbf00" />
        <Bar dataKey="UniqueSubsystem" stackId="a" fill="#82ca9d" />
     
       
      </BarChart>
        </div>
      </div>
      {/* <div className='ml-20 mt-5 flex row'>
        
        <div className='col'>
        <h1 className='text-left font-bold text-2xl ml-20 pl-20'>No of MRs to Drop 10 :</h1>
      <BarChart
        width={500}
        height={300}
        data={this.state.gl['drop10']}
       
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="ThisWeek" stackId="a" fill="#ffbf00" />
        <Bar dataKey="LastWeek" stackId="a" fill="#82ca9d" />
        
       
      </BarChart>
        </div>
        <div className='col'>
        <h1 className='text-left font-bold text-2xl ml-20 pl-20'>No of MRs to Drop 8 :</h1>
      <BarChart
        width={500}
        height={300}
        data={this.state.gl['drop8']}
       
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="ThisWeek" stackId="a" fill="#ffbf00" />
        <Bar dataKey="LastWeek" stackId="a" fill="#82ca9d" />
        
       
      </BarChart>
        </div>
        <div className='col'>
        <h1 className='text-left font-bold text-2xl ml-20 pl-20'>No of MRs to Master :</h1>
      <BarChart
        width={500}
        height={300}
        data={this.state.gl['master']}
       
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="ThisWeek" stackId="a" fill="#ffbf00" />
        <Bar dataKey="LastWeek" stackId="a" fill="#82ca9d" />
        
       
      </BarChart>
        </div>
      </div> */}
      <div>
      {/* <h2 className='text-center text-2xl font-bold'>Pipeline Status for Orin</h2>
      <Line options={options} data={data} /> */}
    
    </div>
      </>:<>
   <div className="flex row">
     <div width="500" className="invisible col w-1/3 ml-20">
       A
     </div>

     <SpinnerCircular
       size={150}
       className="col grid h-screen ml-20 place-items-center"
       role="status"
     />
   </div>
 </>}</>
      
    );
  }
}
export default Stacked;