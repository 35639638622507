const getEnv = (fullName = false) => {
  let env;
  switch (window.location.hostname) {
    case 'ecu-nfs-app-prod-jqwplph33a-ew.a.run.app':
      env = fullName ? 'production' : 'prod';
      break;
    case 'ecu-nfs-app-staging-iruyx3ozka-ew.a.run.app':
      env = 'sandbox';
      break;
    default:
      env = fullName ? 'development' : 'dev';
  }
  return env
}
const getTenantId = () => {
  let tenentId;
  switch (window.location.hostname) {
    case 'ice-app-prod-jqwplph33a-ew.a.run.app':
      tenentId = 'nightletter-ef0yi';
      break;
    case 'ice-app-staging-iruyx3ozka-ew.a.run.app':
      tenentId = 'nightletter-ef0yi';
     
      break;
    default:
      tenentId = 'nightletter-ef0yi';
      
  }
  return tenentId;
}

export const appConfig = {
  env: getEnv(),
  ssoTenantId: getTenantId(),
  environment: getEnv(true),
  URL: `http${window.location.hostname === 'localhost' ? '' : 's'}://${window.location.hostname}${window.location.hostname === 'localhost' ? ':8091' : ''}/api/`,
  newURL: `http${window.location.hostname === 'localhost' ? '' : 's'}://${window.location.hostname}${window.location.hostname === 'localhost' ? ':8091' : ''}/api`,
  // Frontend Logging Config
  EnableConsoleLogging: 'true', // If set to false nothing will log to browser console
  EnableFileLogging: 'true', // If set to false no logs will be sent to the server for file logging
  ConsoleLogLevel: 'debug', // Console Log level - For instance if set to info only info will print to console. If set to debug then debug above will print to console
  FileLogLevel: 'debug', // File Log Level -  For instance if set to error only error will print to console. If set to debug then debug above will print to console
  throttleInterval: 1000, // How often send log record batches to server (ms)
  
};

