
import React from 'react';
import { Link, useLocation } from "react-router-dom";
import { Typography } from "@material-tailwind/react";
const Table =(props)=>{
    const location = useLocation();
    
    const table=location.state.data
    console.log(table)
    
return (
    <>
    {table.length!==0?
    <div className='flex row'>
    <table className="col table-auto text-left">
    <thead>
      <tr>
        
          <th  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
           
              Master This Week
            
          </th>
          
       
      </tr>
    </thead>
    <tbody>
      {table.map(( data) => (
        
        <tr  className="even:bg-blue-gray-50/50">
          
          <td className="p-4">
            
              {data['Master_MR_this_week']}
          
          </td>
          
        </tr>
      ))}
    </tbody>
  </table>
  <table className="col table-auto text-left">
  <thead>
    <tr>
      
        <th  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
         
            Master last Week
          
        </th>
        
     
    </tr>
  </thead>
  <tbody>
    {table.map(( data) => (
      
      <tr  className="even:bg-blue-gray-50/50">
        
        <td className="p-4">
          
            {data['Master_MR_last_week']}
        
        </td>
        
      </tr>
    ))}
  </tbody>
</table>
<table className="col table-auto text-left">
    <thead>
      <tr>
        
          <th  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
           
              Drop 8 This Week
            
          </th>
          
       
      </tr>
    </thead>
    <tbody>
      {table.map(( data) => (
        
        <tr  className="even:bg-blue-gray-50/50">
          
          <td className="p-4">
            
              {data['Drop_8_this_week']}
          
          </td>
          
        </tr>
      ))}
    </tbody>
  </table>
  <table className="col table-auto text-left">
  <thead>
    <tr>
      
        <th  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
         
            Drop 8 last Week
          
        </th>
        
     
    </tr>
  </thead>
  <tbody>
    {table.map(( data) => (
      
      <tr  className="even:bg-blue-gray-50/50">
        
        <td className="p-4">
          
            {data['Drop_8_last_week']}
        
        </td>
        
      </tr>
    ))}
  </tbody>
</table>
<table className="col table-auto text-left">
    <thead>
      <tr>
        
          <th  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
           
              Drop 10 This Week
            
          </th>
          
       
      </tr>
    </thead>
    <tbody>
      {table.map(( data) => (
        
        <tr  className="even:bg-blue-gray-50/50">
          
          <td className="p-4">
            
              {data['Drop_10_this_week']}
          
          </td>
          
        </tr>
      ))}
    </tbody>
  </table>
  <table className="col table-auto text-left">
  <thead>
    <tr>
      
        <th  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
         
            Drop 10 last Week
          
        </th>
        
     
    </tr>
  </thead>
  <tbody>
    {table.map(( data) => (
      
      <tr  className="even:bg-blue-gray-50/50">
        
        <td className="p-4">
          
            {data['Drop_10_last_week']}
        
        </td>
        
      </tr>
    ))}
  </tbody>
</table>
  </div>:null}
  </>
 );
}
export default Table;
