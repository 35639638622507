import React, { Component } from 'react';
import "./common.css";
import { Link } from "react-router-dom";
import { SpinnerCircular } from "spinners-react";
import { withAuthorization } from "./Session";
import { compose } from "recompose";
import { withFirebase } from "../firebase";
import { AuthUserContext } from "./Session";
import fetchClient from '../utils/fetchClient';
// import { auth } from 'google-auth-library'
class Admin extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
     
      token: null,
      error: null,
      process:false
    
    };
    
  }
  componentDidMount() {
   console.log('hello home')
  //  this.gettoken()
  }
  // async gettoken(){
  //   try {
  //     // Create a Google Auth client with the target audience
  //     const audience = 'https://nightletter-backend-kad4mdllqa-nw.a.run.app'; // Replace with your audience
  //     const client = await auth.getIdTokenClient(audience);
 
  //     // Get the token
  //     const token = await client.getIdToken();
  //     this.setState({ token:token });
  //     console.log('Token obtained:', token);
  //   } catch (error) {
  //     this.setState({ error: 'Could not obtain token: ' + error.message });
  //     console.error(error);
  //   }
  // }
 
  handleJira = async() => {
    console.log('button')
   
    this.setState({process:true})
   
      // Using fetch to fetch the api from 
      // flask server it will be redirected to proxy
      try{
        const response=await fetchClient("/runletter")
        const resp = await response.json();
        console.log("Obj returned from server", resp)
        this.setState({process:false})
      }catch(error){
        console.log('Fetch error: ', error);
      }
      
      // fetch("https://nightletter-fmcb5zvjza-nw.a.run.app/api/runletter").then((res) =>
      //     res.json().then((data) => {
      //         // Setting a data from api
      //       console.log(data)
      //     })
      // );

  };
  handlebq= async() => {
    console.log('button')
   
    
    this.setState({process:true})
      // Using fetch to fetch the api from 
      // flask server it will be redirected to proxy
      try{
        const response=await fetchClient("/runbq")
        const resp = await response.json();
        console.log("Obj returned from server", resp)
        this.setState({process:false})
            
          
      
    }catch(error){
      console.log('Fetch error: ', error);
    }

  };
  handlegitlab = async() =>  {
    console.log('button')
   
    this.setState({process:true})
    // Using fetch to fetch the api from 
    // flask server it will be redirected to proxy
    try{
      const response=await fetchClient("/rungl")
      const resp = await response.json();
      console.log("Obj returned from server", resp)
      this.setState({process:false})
          
        
    
  }catch(error){
    console.log('Fetch error: ', error);
  }
   
      // Using fetch to fetch the api from 
      // flask server it will be redirected to proxy
     
  };
  handlesp = async() => {
    console.log('button')
   
    this.setState({process:true})
   
      // Using fetch to fetch the api from 
      // flask server it will be redirected to proxy
      try{
        const response=await fetchClient("/runsp")
        const resp =await response.json();
        console.log("Obj returned from server", resp)
        this.setState({process:false})
      }catch(error){
        console.log('Fetch error: ', error);
      }

  };
  handlecompmr= async() =>{
    console.log('button')
   
    this.setState({process:true})
      // Using fetch to fetch the api from 
      // flask server it will be redirected to proxy
      try{
        const response=await fetchClient("/runcompmr")
        const resp = await response.json();
        console.log("Obj returned from server", resp)
        this.setState({process:false})
            
          
      
    }catch(error){
      console.log('Fetch error: ', error);
    }

   
     
     

  };
  handledrop = async() =>{
    console.log('button')
    this.setState({process:true})
    // Using fetch to fetch the api from 
    // flask server it will be redirected to proxy
    try{
      const response=await fetchClient("/rundrop")
      const resp = await response.json();
      console.log("Obj returned from server", resp)
      this.setState({process:false})
          
        
    
  }catch(error){
    console.log('Fetch error: ', error);
  }

    
   

  };
  handlemrdata = async() => {
    console.log('button')
   
    this.setState({process:true})
      // Using fetch to fetch the api from 
      // flask server it will be redirected to proxy
      try{
        const response=await fetchClient("/runmrdata")
        const resp = await response.json();
        console.log("Obj returned from server", resp)
        this.setState({process:false})
            
          
      
    }catch(error){
      console.log('Fetch error: ', error);
    }
   
     
  };
  handlevbstatus = async() => {
    console.log('button')
   
    this.setState({process:true})
      // Using fetch to fetch the api from 
      // flask server it will be redirected to proxy
      try{
        const response=await fetchClient("/runvbdata")
        const resp = await response.json();
        console.log("Obj returned from server", resp)
        this.setState({process:false})
            
          
      
    }catch(error){
      console.log('Fetch error: ', error);
    }
   
     
  };
  handlekpistatus = async() => {
    console.log('button')
   
    this.setState({process:true})
      // Using fetch to fetch the api from 
      // flask server it will be redirected to proxy
      try{
        const response=await fetchClient("/runkpidata")
        const resp = await response.json();
        console.log("Obj returned from server", resp)
        this.setState({process:false})
            
          
      
    }catch(error){
      console.log('Fetch error: ', error);
    }
   
     
  };
  handletarget= async() => {
    console.log('button')
   
    this.setState({process:true})
      // Using fetch to fetch the api from 
      // flask server it will be redirected to proxy
      try{
        const response=await fetchClient("/runtargetdata")
        const resp = await response.json();
        console.log("Obj returned from server", resp)
        this.setState({process:false})
            
          
      
    }catch(error){
      console.log('Fetch error: ', error);
    }
   
     
  };
  render() {
   
    return (
     

      <>
      {!this.state.process?
      <div style={{ overflowY: "auto" }} className="overflow-hidden min-h-screen ">
      


<p className='mt-5 text-center text-4xl font-bold'>Update Nightletter</p>

<button onClick={()=>this.handleJira()}style={{maxWidth:'500px',maxHeight:'500px'}}  className="mt-20 ml-10 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">

<h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">JIRA Update</h5>
<p class="pl-5 mr-5 font-normal text-gray-700 dark:text-gray-400">Fetches data from JIRA and updates the night letter</p>
</button>
<button onClick={()=>this.handlebq()} style={{maxWidth:'500px',maxHeight:'500px'}}  className="mt-20 ml-10 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">

<h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Bigquery Update</h5>
<p class="pl-5 mr-5 font-normal text-gray-700 dark:text-gray-400">Fetches data from Bigquery and updates the night letter</p>
</button>
<button onClick={()=>this.handlegitlab()} style={{maxWidth:'500px',maxHeight:'500px'}}  className="mt-20 ml-10 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">

<h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Gitlab Update</h5>
<p class="pl-5 mr-5 font-normal text-gray-700 dark:text-gray-400">Fetches data from Gitlab and updates the night letter</p>
</button>
<button onClick={()=>this.handlesp()} style={{maxWidth:'500px',maxHeight:'500px'}}  className="mt-20 ml-10 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">

<h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Sharepoint Update</h5>
<p class="pl-5 mr-5 font-normal text-gray-700 dark:text-gray-400">Fetches data from Sharepoint and updates the night letter</p>
</button>

<button onClick={()=>this.handlecompmr()} style={{maxWidth:'500px',minWidth:'500px',maxHeight:'500px'}}  className="mt-20 ml-10 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">

<h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Component MR Update</h5>
<p class="pl-5 mr-5 font-normal text-gray-700 dark:text-gray-400">Fetches data from GL</p>
</button>
<button onClick={()=>this.handledrop()} style={{maxWidth:'500px',minWidth:'500px',maxHeight:'500px'}}  className="mt-20 ml-10 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">

<h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Run Drop8/Drop10 data</h5>
<p class="pl-5 mr-5 font-normal text-gray-700 dark:text-gray-400">Fetches data from GL</p>
</button>
<button onClick={()=>this.handlemrdata()} style={{maxWidth:'500px',minWidth:'500px',maxHeight:'500px'}}  className="mt-20 ml-10 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">

<h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Get last two weeks MR data</h5>
<p class="pl-5 mr-5 font-normal text-gray-700 dark:text-gray-400">Fetches data from GL</p>
</button>
<button onClick={()=>this.handlevbstatus()} style={{maxWidth:'500px',minWidth:'500px',maxHeight:'500px'}}  className="mt-20 ml-10 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">

<h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Get VB data</h5>
<p class="pl-5 mr-5 font-normal text-gray-700 dark:text-gray-400">Fetches data from confluence</p>
</button>
<button onClick={()=>this.handlekpistatus()} style={{maxWidth:'500px',minWidth:'500px',maxHeight:'500px'}}  className="mt-20 ml-10 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">

<h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Get KPI data</h5>
<p class="pl-5 mr-5 font-normal text-gray-700 dark:text-gray-400">Fetches data from confluence</p>
</button>
<button onClick={()=>this.handletarget()} style={{maxWidth:'500px',minWidth:'500px',maxHeight:'500px'}}  className="mt-20 ml-10 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">

<h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Get Achievements and targets</h5>
<p class="pl-5 mr-5 font-normal text-gray-700 dark:text-gray-400">Fetches data from confluence</p>
</button>
   </div>
   :<>
   <div className="flex row">
     <div width="500" className="col w-1/3 ml-20">
       Running the Letter
     </div>

     <SpinnerCircular
       size={150}
       className="col grid h-screen ml-20 place-items-center"
       role="status"
     />
   </div>
 </>}
    </>
   

    );
  }
}

export default Admin;

const condition = (authUser) => authUser;

// export default compose(withAuthorization(condition))(Admin);

