// src/utils/fetchClient.js
const BASE_URL = "https://nightletter-13190878184.europe-west2.run.app/api"; // Replace with your actual base URL

const fetchClient = async (endpoint, options = {}) => {
  const url = `${BASE_URL}${endpoint}`;
  console.log(url)

  const defaultOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    ...options,
  };

  try {
    const response = await fetch(url, defaultOptions);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response;
  } catch (error) {
    console.error('Fetch error:', error);
    throw error; // Re-throw the error for handling in the calling function
  }
};

export default fetchClient;