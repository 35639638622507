import React, { Component } from 'react';
import Home  from "./components/home.js";
import Landing from './components/landing.jsx';
import Admin from './components/admin.js';
import Table from './components/table.js';
import Graph from './components/graph.js';
import Stacked from './components/historicalGraph.js';
import DiffGraph from './components/diffgraph.js';
import  {withAuthentication}  from './components/Session';
import { AuthUserContext } from './components/Session';
import { BrowserRouter as Router,Redirect, Route, Switch } from "react-router-dom";



class App extends Component {
  constructor(props) {

    super(props);
    this.state = {
      authUser: null,
      version: '',
      authAttempt: false,
    };
  
  }
  componentDidMount() {
    console.log(this.props)
    this.listener = this.props.firebase.auth.onAuthStateChanged(
      authUser => {
        authUser
          ? this.setState({ authUser, authAttempt: true })
          : this.setState({ authUser: null, authAttempt: true });
      },
    );


  }
 
  render() {
   
    return (

    <Router> 
      <div sticky="top" className='block' >
          {this.state.authUser === null ? null :
            <div>
            </div>
          }
        </div>
        <div>
        <Switch authUser>
        {this.state.authUser === null ?
              <>
                {this.state.authAttempt === false ?
                         <>
         
                         {null}
                         </>
                         :
                         <>
                         {console.log('logged out')}
                         {/* <Route path="/" exact={true} component={Landing} /> */}
               
       
                         <Route exact={true} component={Landing}/>
                        
                         </>
                }
                <AuthUserContext.Consumer>
                  {authUser =>
                    authUser ?
                      null :
                      <>
                      {console.log("here")}
                      {/* <Route path='*' render={() => (<Redirect to="/" />)} /> */}
                      </>
                  }
                </AuthUserContext.Consumer>
              </>
              
              :
              <>
                 
                 
       <div className=''>
       <Route  path="/" exact={true} render={() => (<Redirect to="home" />)} />
       <Route  path="/home" component={Home}  />
       <Route  path="/landing" element={<Landing/>}  />
       <Route  path="/admin" component={Admin}  />
       <Route  path="/table" element={<Table />}  />
       <Route  path="/graph" component={Graph }  />
       <Route  path="/stack" component={Stacked }  />
       <Route  path="/diff" component={DiffGraph }  />

       </div>
       </>
            }
           
       </Switch>
       </div>
       
       </Router>
    );
  }
}

export default withAuthentication(App);

