import React from "react";
import "../tailwind.output.css";
import "./common.css";
import SignInButton from "./ssoSignIn";
import logo from "./images/light.png";
import ice from "./images/moon.jpg";
import { Link } from "react-router-dom";
export default function Landing() {
  return (
    <div className="h-screen landing">
        
      <div className="w-full h-screen  flex justify-center items-center">
        <div className=" h-screen w-screen relative z-0 flex flex-col">
          <div className=" w-screen">
            <div className="max-w-7xl mx-auto pt-8">
              <img className="h-14  w-max pl-4 "src={logo} alt="Workflow" />
            </div>
          </div>

          <div className="absolute  w-screen h-screen inset-0 flex justify-center items-center z-10">
            <div className="w-screen h-auto">
          
              <div className="max-w-7xl mx-auto  px-3 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between flex-wrap">
                  <div className=" content-center w-0 flex-1 flex items-center">
                    <div className="text-white mt-0  w-full">
                      <section className=" py-5 px-2 lg:px-0 mt-1 sm:mt-5 flex w-full container mx-auto  ">
                        <div className="w-full text-white flex justify-center md:justify-start text-left md:text-left">
                          <div
                            style={{ width: "1500px" }}
                            className="p-4 backdrop-blur border rounded-2xl  md:w-2/3 md:pt-5 w-full"
                          >
                            <div className="leading-none  font-bold text-2xl xs:text-2x1 md:text-5xl lg:6x1 ">
                              <div className=" flex row-auto">
                                <img
                                  className="h-30 w-20 "
                                  src={ice}
                                  alt="Workflow"
                                />
                                <span className="text-7xl ml-5 pr-3">LOKI Nightletter</span>{" "}
                              
                              </div>

                              
                            </div>
                           
                            <p className="text-black mt-5 mb-12 text-3xl font-semibold ">
                            View of project health consolidated from various data sources
                            </p>
                            {/* <Link
                        to={{
                          pathname: `/home`,
                          
                        }}
                        style={{backgroundColor:'black'}} className='mr-2 bg-grey-700 inline-flex border items-center justify-center py-2 px-4 rounded-md text-white text-xl hover:text-white hover:bg-gray-700'
                      >View
                      </Link> */}
                      <SignInButton />
                            {/* <SignInButton /> */}
                            
                          </div>
                          <div className="md:w-1/2 md:justify-center md:flex hidden">
                          
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
