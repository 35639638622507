
import React, { Component } from 'react';
import BarChart from './barChart';
import { SpinnerCircular } from "spinners-react";
import fetchClient from '../utils/fetchClient';
class Graph extends Component{


  constructor(props) {
    super(props);
    
 
	
    this.state = {
     unique_cat:[],
     sub_data:[],
    comp_data:[]
     

    
    };
    
  }

  componentDidMount() {
   console.log('hello graph')
     
   
      // Using fetch to fetch the api from 
      // flask server it will be redirected to proxy
      fetchClient("/getgraph").then((res) =>
        res.json().then((data) => {
            // Setting a data from api
          console.log(data)
          for(var i=0;i<data.length;i++){
            if(!this.state.unique_cat.includes(data[i]['Diff Category']) && data[i]['Diff Category']!='Diff_Category'){
                this.state.unique_cat.push(data[i]['Diff Category'])
            }
          }
          this.setState({unique_cat:this.state.unique_cat})
          let graph=[]
          for(var i=0;i<this.state.unique_cat.length;i++){
            let cat=this.state.unique_cat[i]
            let unique_sub=[]
            let unique_comp=[]
            for(var j=0;j<data.length;j++){
                if(!unique_sub.includes(data[j]['Sub-System']) && data[j]['Diff Category']==cat){
                    unique_sub.push(data[j]['Sub-System'])
                }
                if(!unique_comp.includes(data[j]['Component'])&& data[j]['Diff Category']==cat){
                    unique_comp.push(data[j]['Component'])
                }
            }
            graph.push({cat:cat,comp:unique_comp.length,sub:unique_sub.length})
          }
          for(var i=0;i<graph.length;i++){
            this.state.comp_data.push(graph[i]['comp'])
            this.state.sub_data.push(graph[i]['sub'])
          }
          this.setState({comp_data:this.state.comp_data,sub_data:this.state.sub_data})
        })
    );
  }

 
  render() {
  
    return (
      <>
      {this.state.comp_data.length!==0?
      <div style={{ overflowY: "auto" }} className="overflow-hidden min-h-screen ">
      <BarChart label_value={this.state.unique_cat} data_value={this.state.sub_data} text_value="Sub-system release delta from Master/branches" cat="Count of Sub System"/>
      <BarChart label_value={this.state.unique_cat} data_value={this.state.comp_data} text_value="Component release delta from Master/branches" cat="Count of Component "/>

   </div>
   :<>
   <div className="flex row">
     <div width="500" className="invisible col w-1/3 ml-20">
       A
     </div>

     <SpinnerCircular
       size={150}
       className="col grid h-screen ml-20 place-items-center"
       role="status"
     />
   </div>
 </>}
    </>
    );
  }
}

export default Graph;

