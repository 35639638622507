import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import { firebaseConfig } from "../env/firebase-config";

import { appConfig } from "../env/vars";

// firebase initialise
class Firebase {
  constructor() {
    firebase.initializeApp({ ...firebaseConfig, popupRedirectResolver: undefined });
    
    firebase.auth().tenantId = appConfig.ssoTenantId;
    console.log(appConfig.ssoTenantId)
    this.auth = firebase.auth();
    this.db = firebase.firestore();
    this.storage = firebase.storage();
    this.ssoProvider = new firebase.auth.SAMLAuthProvider("saml.nightletter");
    this.token = null;
  }


  getCurrentIdToken = () => {
    return this.auth.currentUser.getIdToken();
  };

  // Auth API
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    this.auth.signOut();
  };

  doSignInWithSAML = () => {
    this.auth.signInWithPopup(this.ssoProvider);
    // this.auth.signInWithRedirect(this.ssoProvider);
  };

  doGetRedirectResult = () => this.auth.getRedirectResult();

  doEmailSet = (email) => firebase.auth().currentUser.updateEmail(email);

  doUpdateRole = (uid, email, role, checked) => {
    if (checked) {
      this.db
        .collection("loki-users")
        .doc(uid)
        .update({
          ["roles." + role]: role,
        });
    } else {
      this.db
        .collection("loki-users")
        .doc(uid)
        .update({
          ["roles." + role]: firebase.firestore.FieldValue.delete(),
        });
    }
  };

  // Merge Auth and DB User API

  onAuthUserListener = (next, fallback) =>
    
    this.auth.onAuthStateChanged(async (authUser) => {
      console.log(authUser)
      if (authUser) {
        const user = await authUser.getIdTokenResult();
        const ssoAuthUser = user.claims.firebase;
       
        try {
          const fb = await firebase
            .auth()
            .currentUser.updateEmail(ssoAuthUser.sign_in_attributes['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress']);
            const fbdoc= await firebase.firestore().doc(`loki-users/${authUser.uid}`)
            const dbUserRef = await firebase.firestore().doc(`loki-users/${authUser.uid}`).get({ source: 'server' });
            const dbUser = dbUserRef.data();
            // console.log(dbUser)

            if(dbUser == undefined || dbUser == null)
              {
                fbdoc.set(
                  {
                    firstName: ssoAuthUser.sign_in_attributes['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'],
                    surname: ssoAuthUser.sign_in_attributes['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname'],
                    email: ssoAuthUser.sign_in_attributes['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'],
                    CDSID: authUser.providerData[0].uid,
                    roles: {},
                    registeredAt: firebase.firestore.FieldValue.serverTimestamp(),
                  },
                  { merge: true }
                );

              }
              else{
                fbdoc.update(
                  {
                    firstName: ssoAuthUser.sign_in_attributes['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'],
                    surname: ssoAuthUser.sign_in_attributes['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname'],
                    email: ssoAuthUser.sign_in_attributes['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'],
                    CDSID: authUser.providerData[0].uid,
                    // roles: {},
                    // registeredAt: firebase.firestore.FieldValue.serverTimestamp(),
                  }
                )
              }
        
        } catch (ex) {
          console.debug('User Already Exists');
        }

        const dbUserRef = await firebase.firestore().doc(`loki-users/${authUser.uid}`).get({ source: 'server' });

        const dbUser = dbUserRef.data();
        console.log(dbUser)

        let success;
        // default empty roles
        if ((dbUser === null) | (dbUser === undefined)) {
          dbUser = {};
          dbUser.roles = {};
          dbUser.error = true;
          success = false;
        }

        if (!dbUser.roles) {
          dbUser.roles = {};
        }
        // merge auth and db user
        authUser = {
          ...dbUser,
          uid: authUser.uid,
        };
        if (success !== false) {
          await this.users().doc(authUser.uid).update({
            lastActive: firebase.firestore.FieldValue.serverTimestamp(),
          });
        }

        console.debug('Passing on', authUser);

        next(authUser);
      } else {
        fallback();
      }
    });

  // Firestore Api

  user = (uid) => this.db.doc(`loki-users/${uid}`);
  users = () => this.db.collection("loki-users");
 
}
export default Firebase;
