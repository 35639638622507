import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
const BarChart = (label_value,data_value,text_value,cat) => {
    console.log(label_value)
    console.log(text_value)
 const data = {
   labels: label_value['label_value'],
   datasets: [
     {
       label: 'Count',
       data:label_value['data_value'],
       backgroundColor: 'rgba(75, 192, 192, 0.2)',
       borderColor: 'rgba(75, 192, 192, 1)',
       borderWidth: 1,
     },
   ],
 };
 const options = {
   responsive: true,
   plugins: {
     legend: {
       position: 'top',
     },
     title: {
       display: true,
       text: label_value['text_value'],
     },
   },
   scales: {
     x: {
       title: {
         display: true,
         text: 'Delta in weeks',
       },
     },
     y: {
       title: {
         display: true,
         text: label_value['cat'],
       },
     },
   },
 };
 return <Bar data={data} options={options} />;
};
export default BarChart;