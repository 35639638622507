import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../firebase';
import { Link } from 'react-router-dom';




const ssoSignIn = () => (
        <SSOSignInForm />
);

class SignInFormBase extends Component {
    constructor(props) {
 
        super(props);
        
        this.state = {
     path:props.location.pathname
        };
      
      }
    onSignIn = event => {
      console.log(this.props)
        this.props.firebase.doSignInWithSAML(this.state.path);
        // this.props.firebase.doredirect(this.state.path);
        try{
            this.auth.onAuthStateChanged((authUser) => {
                if (authUser) {
                  // User signed in, you can get redirect result here if needed.
                  // ...
                 console.log('pm')
                  // ....
                } else {
                  // Show sign in screen with button above.
                }
              });
        }catch{
            
        }
     
    }

    render() {
        return (
            <>
           
            {/* <button style={{backgroundColor:'black'}}className='mr-2 bg-grey-700 inline-flex border items-center justify-center py-2 px-4 rounded-md text-white text-xl hover:text-white hover:bg-gray-700'as={Link} to="/" onClick={()=>{this.onSignIn();this.props.history.push('/')}}>SSO Login</button> */}
            <button style={{backgroundColor:'black'}}className='mr-2 bg-grey-700 inline-flex border items-center justify-center py-2 px-4 rounded-md text-white text-xl hover:text-white hover:bg-gray-700'as={Link}  onClick={()=>{this.onSignIn()}}>SSO Login</button>
       </>
            );
    }
}

const SSOSignInForm = compose(
    withRouter,
    withFirebase,
)(SignInFormBase);

export default ssoSignIn;

export { SSOSignInForm };
